<template>
  <div>
    <div class="personal">
      <div class="nav">
        <div class="ban">
          <ul class="nav-top">
            <p class="yinyou">
              <!-- <i class="img"></i> -->
              <span class="myMember">平台服务</span>
            </p>
            <li v-for="item in nav" :key="item.id" @click="changes(item)">
              <span>{{ item.text }}</span>
              <i :class="chieck === item.id ? 'chieck' : ''"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ban">
      <!--操作指南-->
      <guide v-if="chieck == 0"></guide>
      <!--支付方式-->
      <mode v-if="chieck == 2"></mode>
      <!--配送方式-->
      <distribution v-if="chieck == 3"></distribution>
      <!--售货服务-->
      <aftersales v-if="chieck == 4"></aftersales>
      <!--关于我们 -->
      <introduction v-if="chieck == 5"></introduction>
    </div>
  </div>
</template>

<script>
import guide from "../../components/personal/guide";
import mode from "../../components/personal/mode";
import distribution from "../../components/personal/distribution";
import aftersales from "../../components/personal/aftersales";
import introduction from "../../components/personal/introduction";

export default {
  inject: ["reload"],
  name: "about",
  components: {
    guide,
    mode,
    distribution,
    aftersales,
    introduction,
  },
  data() {
    return {
      chieck: 0,
      nav: [
        {
          id: 0,
          text: "操作指南",
        },
        {
          id: 2,
          text: "支付方式",
        },
        {
          id: 3,
          text: "配送方式",
        },
        {
          id: 4,
          text: "售后服务",
        },
        {
          id: 5,
          text: "关于我们",
        },
      ],
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.chieck = this.$route.query.id;

    console.log(this.$route.query);
    // console.log(this.$store.state.chieck);
    //
    // this.chieck = this.$store.state.chieck;
  },
  methods: {
    // 动态添加下标
    changes(item) {
      this.chieck = item.id;
      this.checked = 0;
    },
  },
  mounted() {
    this.chieck = this.$route.query.id;
    console.log(this.$route.query);
    this.reload();
  },

  beforeRouteUpdate(to, from, next) {
    console.log(to, from, next);
    this.chieck = to.query.id;
    next();
  },
};
</script>

<style lang="scss" scoped>
.personal {
  width: 100%;
  background-color: #f8f8f8;
  font-size: 16px;
  .nav {
    width: 100%;
    height: 50px;
    background-color: #0289f2;

    .nav-top {
      display: flex;

      li {
        width: 160px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;

        .chieck {
          width: 12px;
          height: 8px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          background: url("../../static/imgs/icon_triangle_white.png") no-repeat
            center;
          background-size: cover;
        }
      }

      .yinyou {
        width: 160px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #047bd7;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        // .img {
        //   display: block;
        //   width: 98px;
        //   height: 23px;
        //   background: url("../../static/imgs/personalCenter/mineyy.png")
        //     no-repeat center;
        // }
        .myMember {
          display: block;
          font-size: 20px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}

.ban {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
}
</style>

<!--<style lang="scss">-->

<!--    /*表格样式  */-->
<!--    .table {-->
<!--        .el-table .cell {-->
<!--            text-align: center !important;-->
<!--        }-->
<!--    }-->

<!--    /*  发票申请（按钮样式）  */-->
<!--    .invoice{-->
<!--        .el-row{-->
<!--            display: flex;-->
<!--            margin: 10% auto;-->
<!--            button{-->
<!--                margin: auto;-->
<!--            }-->
<!--        }-->

<!--        /*  申请发票表单  */-->
<!--        .el-form{-->
<!--            margin: 20px auto;-->
<!--            width: 50%;-->
<!--        }-->
<!--    }-->

<!--</style>-->
