<template>
    <div>
        <div class="personalInfo">
            <div class="card-left">
                <div class="top">
                    <i class="iconfont icon-iconcopy"></i>
                    <span>支付方式</span>
                </div>
                <ul class="infos">
                    <li
                            :class="checked === item.id ? 'checked' : ''"
                            :key="item.id"
                            @click="replace(item)"
                            class="info-item"
                            v-for="item in userInfo"
                    >
                        {{ item.text }}
                    </li>
                </ul>
            </div>
            <div class="card-right">
                <!-- 支付宝支付 -->
                <div class="info zfb" v-if="checked === 0">
                    <div class="card">
                        <div class="card-top">
                            <span></span>
                            <h3>支付宝支付</h3>
                        </div>
                        <div class="card-form">
                            <div class="text">
                                <span>新客户强烈推荐:支付宝第三方担保付款</span>
                                <div>支付宝属于阿里巴巴旗下子公司,全程由阿里巴巴公司做担保,由支付宝担保交易付款为目前钱款及货物最安全的支付方式之一,拥有支付宝帐户登陆支付宝即可支付,若没有支付宝帐户,您的其它银行卡只要开通网上银行,同样可以使用支付宝,信用卡还可透支消费,解决您短期资金周转问题.</div>
                                <h5>关于支付宝第三方担保交易</h5>
                                <div>印厂商城已经开通支付宝的担保交易，在会员的支付页面下选择支付宝付款 —》输入“金额” —》点击“在线支付” 成功交易流程： 您付款给支付宝公司，由支付宝公司担保货款安全 —》印厂商城确认款到支付宝公司担保账户后发货 —》您收货后及时通知支付宝公司确认无误已收货—》印厂商城收到支付宝公司支付的货款。（如果您未及时通知支付宝公司收到货并已确认，15天后支付宝公司默认货无误已成功完成交易，将货款自动转入工厂账户）</div>
                                <div>退货交易流程： 您付款给支付宝公司，由支付宝公司担保货款安全 —》印厂商城确认款到支付宝公司账户后发货 —》您收货后确认要退货并及时通知支付宝公司确认退货—》卖家通知支付宝公司确认收到退货—》货款由支付宝公司退回到您的账户。（如果印厂商城未及时通知支付宝公司收到退货并已确认，15天后支付宝公司默认退货无误，将货款自动转入您的账户）</div>
                                <span>交易由第三方支付宝公司担保支付，以保证买卖双方的资金安全。</span>
                                <div>了解详情请登陆支付宝官方网站查看</div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- 银行转账 -->
                <div class="info bank" v-if="checked === 1">
                    <div class="card">
                        <div class="card-top">
                            <span></span>
                            <h3>银行转账</h3>
                        </div>
                        <div class="card-form">
                            <div class="text" v-html="mallBankInfo">
                                {{mallBankInfo}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "mode",
        data() {
            return {
                checked: 0, // 切换模块
                userInfo: [
                    {
                        id: 0,
                        text: "支付宝支付",
                    },
                    {
                        id: 1,
                        text: "银行转账",
                    }
                ],
                mallBankInfo:'',//转账信息
            }
        },
        created() {
            // 类型切换
            // let type = localStorage.getItem("type");
            // if (type) {
            //     this.checked = Number(type);
            // } else {
            //     this.checked = 0;
            // }
            this.checked=this.$route.query.checked
            this.info()
        },
        methods: {
            // 切换列表
            replace(item) {
                // localStorage.setItem("type", item.id); //存入本地，防止刷新页面丢失
                this.checked = item.id;
                console.log(item);
            },

            info(){
                this.$http.get("/bankaccounttransfer/listAll").then((res) => {
                    console.log("转账信息", res);
                    if (res.data.code == 200) {
                        this.mallBankInfo = res.data.data[0].mallBankInfo
                    } else {
                        this.$message.error("查询失败");
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .personalInfo {
        padding: 20px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .card-left {
            width: 200px;
            height: 320px;

            .top {
                width: 100%;
                height: 86px;
                line-height: 86px;
                text-align: center;
                background-color: #047bd7;
                color: #fff;
                font-size: 24px;

                i {
                    font-size: 28px;
                    margin-right: 10px;
                }
            }

            .infos {
                width: 100%;
                box-sizing: border-box;
                // border: 1px solid #e6e6e6;
                // border-top: none;
                li {
                    box-sizing: border-box;
                    width: 100%;
                    height: 55px;
                    text-align: center;
                    line-height: 55px;
                    border-bottom: 1px dashed #e6e6e6;
                    cursor: pointer;
                }

                li:last-child {
                    border-bottom: none;
                }

                .checked {
                    background-color: #058af2;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .card-right {
            width: 980px;
            background-color: #fff;
        }

        .info {
            width: 100%;
            box-sizing: border-box;
            // padding: 15px;
        }

        .card {
            width: 100%;
            .card-top {
                display: flex;
                align-items: center;
                height: 50px;
                border-bottom: 1px solid #e6e6e6;
                padding: 0 30px;
                line-height: 50px;

                span {
                    display: inline-block;
                    width: 5px;
                    height: 17px;
                    background: #058af2;
                    margin-right: 20px;
                }

                h3 {
                    color: #058af2;
                }
            }

            .list {
                width: 100%;
                box-sizing: border-box;
                padding: 5px 30px;

                .list-top {
                    display: flex;
                    justify-content: space-between;

                    span {
                        color: #058af2;
                    }

                    span:last-child:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                .list-item {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .items {
                        margin-top: 10px;
                        width: 255px;
                        height: 155px;
                        background: transparent url("../../static/imgs/beijing1.png") no-repeat scroll 0% 0%;
                        position: relative;

                        .border {
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 14px;
                            font-weight: 400;

                            p {
                                padding: 3px 0 5px 0;

                                border-bottom: 1px solid #e6e6e6;
                            }

                            i {
                                display: inline-block;
                                margin-right: 10px;
                            }

                            p:nth-child(2) {
                                height: 65px;
                                display: flex;
                                align-items: center;

                                span {
                                    display: block;
                                    overflow: hidden;
                                    white-space: wrap;
                                    text-overflow: ellipsis;
                                }
                            }

                            p:last-child {
                                border-bottom: none;
                            }
                        }

                        .top {
                            position: absolute;
                            top: 0;
                            right: 15px;
                            font-size: 14px;
                            height: 20px;
                            line-height: 20px;
                            color: #fff;

                            .top-df {
                                display: none;
                                background: #fbb244 none repeat scroll 0% 0%;
                                padding: 0px 10px;
                                border-radius: 0px 0px 5px 5px;
                            }
                        }

                        .active {
                            display: block;
                            padding: 0 10px;
                            background: #058af2 none repeat scroll 0% 0%;
                        }

                        .top:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        .bottom {
                            display: none;
                            position: absolute;
                            right: 20px;
                            bottom: 8px;
                            cursor: pointer;
                            font-size: 14px;

                            p {
                                float: left;
                                border: 1px solid #d4d4d4;
                                margin-left: 5px;
                                height: 25px;
                                line-height: 25px;
                                width: 40px;
                                text-align: center;
                                border-radius: 3px;
                                background-color: #fff;
                            }

                            p:hover {
                                color: #058af2;
                            }
                        }
                    }

                    .items-bgd {
                        background: transparent url("../../static/imgs/beijing2.png") no-repeat scroll 0% 0%;
                    }

                    .items:hover .top-df {
                        display: block;
                    }

                    .items:hover .bottom {
                        display: block;
                    }

                    .items:nth-last-child(-n + 1) {
                        height: 0;
                        background: none;
                    }
                }
            }
        }

        /*支付宝支付*/
        .zfb {
            .card-form {
                margin: 30px;
                span{
                    font-size: 13px;
                }
                span,h5{
                    color: red;
                    display: block;
                    text-align: left;
                    text-indent: 0rem;
                }
                div{
                    margin: 10px 0;
                    text-indent: 2rem;
                    line-height: 30px;
                }
            }
        }

        /*银行转账*/
        .bank {
            .card-form {
                margin: 30px;
                div{
                    margin: 10px 0;
                    text-indent: 2rem;
                    line-height: 30px;
                }
            }
        }
    }
</style>
