<template>
    <div>
        <div class="personalInfo">
            <div class="card-left">
                <div class="top">
                    <i class="iconfont icon-iconcopy"></i>
                    <span>售后服务</span>
                </div>
                <ul class="infos">
                    <li
                            :class="checked === item.id ? 'checked' : ''"
                            :key="item.id"
                            @click="replace(item)"
                            class="info-item"
                            v-for="item in userInfo"
                    >
                        {{ item.text }}
                    </li>
                </ul>
            </div>
            <div class="card-right">
                <!-- 找回密码 -->
                <div class="info retrieve" v-if="checked === 0">
                    <div class="card">
                        <div class="card-top">
                            <span></span>
                            <h3>找回密码</h3>
                        </div>
                        <div class="card-form">
                            <div class="text">
                                <div>如果您忘记了登录密码，请在会员登录界面点击“忘记密码？”，根据提示，输入手机号和新密码，获取短信验证码并输入确认即可。</div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- 退货原则 -->
                <div class="info goods" v-if="checked === 1">
                    <div class="card">
                        <div class="card-top">
                            <span></span>
                            <h3>退货原则</h3>
                        </div>
                        <div class="card-form">
                            <div class="text">
                                <div>工厂一向非常重视产品的质量，如果您的印刷品出现了由于印刷本身产生的质量问题，经过核实我们一定会为您办理退货或者重印。但是我们对以下原因造成的退货不予处理：</div>
                                <span>A．非我方原因造成的文字或版式错误；</span>
                                <span>B．非我方原因造成的到货时间延误；</span>
                                <span>C．产品签收后产生的受潮、染色等状况；</span>
                                <span>D．退回产品数量不足；</span>
                                <span>E．提供的图片格式及色标有误，造成的模糊、色差等问题</span>
                                <span>F．顾客上传的图片大小、分辨率未达到上传标准而造成的印刷问题</span>
                                <span>H．顾客上传的设计文件不符合系统要求而造成的印刷问题</span>
                                <div>对于稿件不符合规定，请我公司帮忙修改，或设计文档，请您仔细校对，我们会有专人同您进行最终确认，对于未确认，或因为来回校稿拖延时间，产生的交货延迟，我们将不承担任何责任，恕不退货。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aftersales",
        data() {
            return {
                checked: 0, // 切换模块
                userInfo: [
                    {
                        id: 0,
                        text: "找回密码",
                    },
                    {
                        id: 1,
                        text: "退货原则",
                    }
                ],
            }
        },
        created() {
            // 类型切换
            // let type = localStorage.getItem("type");
            // if (type) {
            //     this.checked = Number(type);
            // } else {
            //     this.checked = 0;
            // }
            this.checked=this.$route.query.checked
        },
        methods: {
            // 切换列表
            replace(item) {
                // localStorage.setItem("type", item.id); //存入本地，防止刷新页面丢失
                this.checked = item.id;
                console.log(item);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .personalInfo {
        padding: 20px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .card-left {
            width: 200px;
            height: 320px;

            .top {
                width: 100%;
                height: 86px;
                line-height: 86px;
                text-align: center;
                background-color: #047bd7;
                color: #fff;
                font-size: 24px;

                i {
                    font-size: 28px;
                    margin-right: 10px;
                }
            }

            .infos {
                width: 100%;
                box-sizing: border-box;
                // border: 1px solid #e6e6e6;
                // border-top: none;
                li {
                    box-sizing: border-box;
                    width: 100%;
                    height: 55px;
                    text-align: center;
                    line-height: 55px;
                    border-bottom: 1px dashed #e6e6e6;
                    cursor: pointer;
                }

                li:last-child {
                    border-bottom: none;
                }

                .checked {
                    background-color: #058af2;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .card-right {
            width: 980px;
            background-color: #fff;
        }

        .info {
            width: 100%;
            box-sizing: border-box;
            // padding: 15px;
        }

        .card {
            width: 100%;
            .card-top {
                display: flex;
                align-items: center;
                height: 50px;
                border-bottom: 1px solid #e6e6e6;
                padding: 0 30px;
                line-height: 50px;

                span {
                    display: inline-block;
                    width: 5px;
                    height: 17px;
                    background: #058af2;
                    margin-right: 20px;
                }

                h3 {
                    color: #058af2;
                }
            }

            .list {
                width: 100%;
                box-sizing: border-box;
                padding: 5px 30px;

                .list-top {
                    display: flex;
                    justify-content: space-between;

                    span {
                        color: #058af2;
                    }

                    span:last-child:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                .list-item {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .items {
                        margin-top: 10px;
                        width: 255px;
                        height: 155px;
                        background: transparent url("../../static/imgs/beijing1.png") no-repeat scroll 0% 0%;
                        position: relative;

                        .border {
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 14px;
                            font-weight: 400;

                            p {
                                padding: 3px 0 5px 0;

                                border-bottom: 1px solid #e6e6e6;
                            }

                            i {
                                display: inline-block;
                                margin-right: 10px;
                            }

                            p:nth-child(2) {
                                height: 65px;
                                display: flex;
                                align-items: center;

                                span {
                                    display: block;
                                    overflow: hidden;
                                    white-space: wrap;
                                    text-overflow: ellipsis;
                                }
                            }

                            p:last-child {
                                border-bottom: none;
                            }
                        }

                        .top {
                            position: absolute;
                            top: 0;
                            right: 15px;
                            font-size: 14px;
                            height: 20px;
                            line-height: 20px;
                            color: #fff;

                            .top-df {
                                display: none;
                                background: #fbb244 none repeat scroll 0% 0%;
                                padding: 0px 10px;
                                border-radius: 0px 0px 5px 5px;
                            }
                        }

                        .active {
                            display: block;
                            padding: 0 10px;
                            background: #058af2 none repeat scroll 0% 0%;
                        }

                        .top:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        .bottom {
                            display: none;
                            position: absolute;
                            right: 20px;
                            bottom: 8px;
                            cursor: pointer;
                            font-size: 14px;

                            p {
                                float: left;
                                border: 1px solid #d4d4d4;
                                margin-left: 5px;
                                height: 25px;
                                line-height: 25px;
                                width: 40px;
                                text-align: center;
                                border-radius: 3px;
                                background-color: #fff;
                            }

                            p:hover {
                                color: #058af2;
                            }
                        }
                    }

                    .items-bgd {
                        background: transparent url("../../static/imgs/beijing2.png") no-repeat scroll 0% 0%;
                    }

                    .items:hover .top-df {
                        display: block;
                    }

                    .items:hover .bottom {
                        display: block;
                    }

                    .items:nth-last-child(-n + 1) {
                        height: 0;
                        background: none;
                    }
                }
            }
        }

        /*找回密码*/
        .retrieve {
            .card-form {
                margin: 30px;
                span{
                    font-size: 13px;
                }
                span,h5{
                    color: red;
                    display: block;
                    text-align: left;
                    text-indent: 0rem;
                }
                div{
                    margin: 10px 0;
                    text-indent: 2rem;
                    line-height: 30px;
                }
            }
        }

        /*退货原则*/
        .goods {
            .card-form {
                margin: 30px;
                div{
                    margin: 10px 0;
                    text-indent: 2rem;
                    line-height: 30px;
                }
                span{
                    display: block;
                    text-indent: 0rem;
                }
                span:last-of-type{
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>
