<template>
    <div v-if="isRouterAlive">
        <div class="personalInfo">
            <div class="card-left">
                <div class="top">
                    <i class="iconfont icon-iconcopy"></i>
                    <span>操作指南</span>
                </div>
                <ul class="infos">
                    <li
                            :class="checked === item.id ? 'checked' : ''"
                            :key="item.id"
                            @click="replace(item)"
                            class="info-item"
                            v-for="item in userInfo"
                    >
                        {{ item.text }}
                    </li>
                </ul>
            </div>
            <div class="card-right">
                <!-- 订印流程 -->
                <div class="info order" v-if="checked === 0">
                    <div class="card">
                        <div class="card-top">
                            <span></span>
                            <h3>订印流程</h3>
                        </div>
                        <div class="card-form">
                            <div class="text">
                                <div>仅需四步！本网站将会带给您最方便快捷的印刷品采购体验！</div>
                                <h2>自动报价 > 在线下单上传文件 > 付款印刷 > 发货签收</h2>
                                <h4>一、自动报价：</h4>
                                <div>首先确定你想订购什么类型的印刷品，然后选择对应报价系统，如：样本画册报价。根据提示输入产品规格、数量及工艺要求等，点击自动报价，只需一秒，您就可以轻松获得报价！</div>
                                <h4>二、在线下单上传文件：</h4>
                                <div>点击报价单下方的“生成订单”按钮，系统将会根据您刚刚的报价内容自动生成订单。同时请使用WEB或QQ上传你要印刷的设计稿件（上传之前请务必仔细阅读“完稿须知”）。</div>
                                <h4>三、付款印刷：</h4>
                                <div>在您下达订单后，我们的客服人员会第一时间与您取得联系，确认您的订单内容、工艺要求、价格、工期及付款方式等。同时我们会对您的印刷文件进行检查，不符合印刷要求的我们会对其进行修改，无法修改的我们会和您取得联系。文件检查无误后请根据洽谈内容支付相应印刷费用，支付成功后，您的订单将会被审核通过，进入生产流程。</div>
                                <h4>四、发货签收：</h4>
                                <div>如果约定的交货方式是配送，在约定的日期您将会收到工厂为你送货上门的货物，您只需验货签收； 如果约定的交货方式是自提，请提前一天与客服人员预约并于约定日期前来验货提取。</div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- 完稿须知 -->
                <div class="info notice" v-if="checked === 1">
                    <div class="card">
                        <div class="card-top">
                            <span></span>
                            <h3>完稿须知</h3>
                        </div>
                        <div class="card-form">
                            <div class="text">
                                <div>1、所有文件色彩模式必须为CMYK模式，请勿使用RGB色系。</div>
                                <div>2、文件制作须加3mm出血，另为防止切到文字或其它重要内容，请将文字和重要内容放置位于成品线以内3mm（离出血线6mm）以上的位置。</div>
                                <div>3、所有文字必须转曲，否则易出现乱码现象。</div>
                                <div>4、黑色文字和线条请使用K=100单黑。</div>
                                <div>5、对于大面积黑色我公司使用C50M40Y40K100设置比例。</div>
                                <div>6、请确保重要图片分辩率在300dpi 以上。</div>
                                <div>7、如文件使用了较复杂的效果（如透明度、羽化等），为防止制版时产生不必要的麻烦，请提前将其拼合透明度或转位图。</div>
                                <div>8、样本制作请按：封面、封二、内一、内二、内三……封三、封底顺序制作（如成品尺寸是210*285mm，文件要做成     216*291mm）；    如样本中有使用跨页图片，请按：封底+封面、封二+内一、内二+内三、内四+内五……内N+封三顺序制作（如成品尺寸是210*285mm，文件就要做成426*291mm）。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "guide",
        data() {
            return {
                checked: 0, // 切换模块
                userInfo: [
                    {
                        id: 0,
                        text: "订印流程",
                    },
                    {
                        id: 1,
                        text: "完稿须知",
                    }
                ],

                isRouterAlive: true
            }
        },
        created() {
            console.log(this.$route.query.id)
            console.log(this.$route.query);
            // if (this.$route.params.id==5){
            //     this.checked==0
            // }else{
                this.checked=this.$route.query.checked

            // this.checked = this.$store.state.checked;
            //     console.log(this.$store.state.checked)
            // }
            // 类型切换
            // let type = localStorage.getItem("type");
            // if (type) {
            //     this.checked = Number(type);
            // } else {
            //     this.checked = 0;
            // }
        },
        methods: {
            // 切换列表
            replace(item) {
                // localStorage.setItem("type", item.id); //存入本地，防止刷新页面丢失
                this.checked = item.id;
                console.log(item);
            },

            reload () {
                this.isRouterAlive = false
                this.$nextTick(() => (this.isRouterAlive = true))
            }
        }
    }
</script>

<style lang="scss" scoped>
    .personalInfo {
        padding: 20px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .card-left {
            width: 200px;
            height: 320px;

            .top {
                width: 100%;
                height: 86px;
                line-height: 86px;
                text-align: center;
                background-color: #047bd7;
                color: #fff;
                font-size: 24px;

                i {
                    font-size: 28px;
                    margin-right: 10px;
                }
            }

            .infos {
                width: 100%;
                box-sizing: border-box;
                // border: 1px solid #e6e6e6;
                // border-top: none;
                li {
                    box-sizing: border-box;
                    width: 100%;
                    height: 55px;
                    text-align: center;
                    line-height: 55px;
                    border-bottom: 1px dashed #e6e6e6;
                    cursor: pointer;
                }

                li:last-child {
                    border-bottom: none;
                }

                .checked {
                    background-color: #058af2;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .card-right {
            width: 980px;
            background-color: #fff;
        }

        .info {
            width: 100%;
            box-sizing: border-box;
            // padding: 15px;
        }

        .card {
            width: 100%;
            .card-top {
                display: flex;
                align-items: center;
                height: 50px;
                border-bottom: 1px solid #e6e6e6;
                padding: 0 30px;
                line-height: 50px;

                span {
                    display: inline-block;
                    width: 5px;
                    height: 17px;
                    background: #058af2;
                    margin-right: 20px;
                }

                h3 {
                    color: #058af2;
                }
            }

            .list {
                width: 100%;
                box-sizing: border-box;
                padding: 5px 30px;

                .list-top {
                    display: flex;
                    justify-content: space-between;

                    span {
                        color: #058af2;
                    }

                    span:last-child:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                .list-item {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .items {
                        margin-top: 10px;
                        width: 255px;
                        height: 155px;
                        background: transparent url("../../static/imgs/beijing1.png") no-repeat scroll 0% 0%;
                        position: relative;

                        .border {
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 14px;
                            font-weight: 400;

                            p {
                                padding: 3px 0 5px 0;

                                border-bottom: 1px solid #e6e6e6;
                            }

                            i {
                                display: inline-block;
                                margin-right: 10px;
                            }

                            p:nth-child(2) {
                                height: 65px;
                                display: flex;
                                align-items: center;

                                span {
                                    display: block;
                                    overflow: hidden;
                                    white-space: wrap;
                                    text-overflow: ellipsis;
                                }
                            }

                            p:last-child {
                                border-bottom: none;
                            }
                        }

                        .top {
                            position: absolute;
                            top: 0;
                            right: 15px;
                            font-size: 14px;
                            height: 20px;
                            line-height: 20px;
                            color: #fff;

                            .top-df {
                                display: none;
                                background: #fbb244 none repeat scroll 0% 0%;
                                padding: 0px 10px;
                                border-radius: 0px 0px 5px 5px;
                            }
                        }

                        .active {
                            display: block;
                            padding: 0 10px;
                            background: #058af2 none repeat scroll 0% 0%;
                        }

                        .top:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        .bottom {
                            display: none;
                            position: absolute;
                            right: 20px;
                            bottom: 8px;
                            cursor: pointer;
                            font-size: 14px;

                            p {
                                float: left;
                                border: 1px solid #d4d4d4;
                                margin-left: 5px;
                                height: 25px;
                                line-height: 25px;
                                width: 40px;
                                text-align: center;
                                border-radius: 3px;
                                background-color: #fff;
                            }

                            p:hover {
                                color: #058af2;
                            }
                        }
                    }

                    .items-bgd {
                        background: transparent url("../../static/imgs/beijing2.png") no-repeat scroll 0% 0%;
                    }

                    .items:hover .top-df {
                        display: block;
                    }

                    .items:hover .bottom {
                        display: block;
                    }

                    .items:nth-last-child(-n + 1) {
                        height: 0;
                        background: none;
                    }
                }
            }
        }

        /*订印流程*/
        .order {
            .card-form {
                margin: 30px;
                h2{
                    color: red;
                    text-align: center;
                    margin: 15px 0;
                }
                h4{
                    text-indent: 0rem;
                }
                h4,div{
                    margin: 10px 0;
                }
                div{
                    text-indent: 2rem;
                    line-height: 30px;
                }
            }
        }

        /*完稿须知*/
        .notice {
            .card-form {
                margin: 30px;
                div{
                    margin: 10px 0;
                    text-indent: 2rem;
                    line-height: 30px;
                }
            }
        }
    }
</style>
